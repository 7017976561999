import './sass/main.scss'
import lazyframe from 'lazyframe';

if (document.querySelector(".timeline-block__container")) {
    import("./scripts/components/splide-timeline").then((init) => init.default());
}
if (document.querySelector(".carousel-block__container")) {
    import("./scripts/components/splide-carousel").then((init) => init.default());
}
if (document.querySelector("#vacancieslisting")) {
    import("./scripts/components/vacancies-results").then((init) => init.default());
}
lazyframe(".lazyframe");
